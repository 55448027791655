


































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { ElForm } from 'element-ui/types/form'
import { validatePass } from '@/utils/tools'
import { ElInput } from 'element-ui/types/input'
import { changeAvatarUrl, changeUserInfo } from '@/api/users'
import { UserModule } from '@/store/modules/user'

@Component({
  name: '',
  components: {
    moduleHeader
  }
})
export default class extends Vue {
  imageUrl = this.userInfo.avatar
  validatePass = validatePass
  changeAvatarUrl = changeAvatarUrl
  uploadParam = { ownerType: 'User' }
  uploadHeader = {
    'Authorization': 'bearer ' + window.sessionStorage.getItem('token')
  }

  handleAvatarSuccess(res:any, file: any) {
    if (res.statusCode === 0 && res.data) {
      // this.$message({
      //   type: 'success',
      //   message: '上传成功'
      // })

      this.imageUrl = res.data
      // UserModule.SET_USERDATA_ITEM({ field: 'avatar', value: res.data })
    } else {
      this.$message({
        type: 'error',
        message: res.errorMsg
      })
    }
  }

  get userInfo() {
    return UserModule.userInfo
  }

  beforeAvatarUpload(file: File) {
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isLt2M) {
      this.$message.error('上传头像图片大小不能超过 2MB!')
    }
    return isLt2M
  }

  handleErr(err: any, file: File) {
    console.log('err2', err)
  }

  onBack() {
    this.$router.back()
  }

  async confirm() {
    const info = this.userInfo
    info.avatar = this.imageUrl
    const userRes = await changeUserInfo(info)
    if (userRes.statusCode === 0 && userRes.data) {
      UserModule.SET_USERDATA(info)
      this.$message({
        type: 'success',
        message: '修改成功'
      })
      this.$router.back()
    }
  }
}
